<template>
    <v-container style="display: flex; flex-direction: row">
        <div class="container-bg" style="display: block; margin: auto">
            <div class="container-fg">
                <div style="display: flex; flex-direction: row">
                    <img
                        style="width: 130px; margin-bottom: 10px"
                        src="../../assets/logos/getac-logo.svg"
                    />
                    <div class="text-h7 nav-font pl-2" style="margin-top: 15px"
                        >PARTS ORDER</div
                    >
                </div>
                <v-alert
                    v-if="partsOrderWarningMessage"
                    border="left"
                    color="blue-grey"
                    dark
                    dense
                    class="mt-5"
                    type="info"
                >
                    {{ partsOrderWarningMessage }}
                </v-alert>
                <v-divider style="margin-bottom: 15px; border-color: gray" />
                <v-row align="start">
                    <v-col cols="6">
                        <base-info-card
                            title="Contact Information"
                            color="primary"
                            body-space="0"
                        />
                        <contact-form
                            ref="contactForm"
                            dense
                            dark
                            :user-stored-contact="userStoredContact"
                            @contactInput="Object.assign(contactData, $event)"
                            @form-is-valid="contactFormIsValid = $event"
                        />
                        <base-info-card
                            title="Shipping Address"
                            color="primary"
                            body-space="0"
                        />
                        <address-form
                            ref="addressForm"
                            dense
                            dark
                            :user-stored-address="userStoredAddress"
                            :apo="isApo"
                            :cities="cities"
                            :countries="countries"
                            :state-provinces="stateProvinces"
                            :states-loading="statesLoading"
                            :cities-loading="citiesLoading"
                            @stateUpdated="getCities($event)"
                            @countryUpdated="getStates($event)"
                            @addressInput="Object.assign(addressData, $event)"
                            @form-is-valid="addressFormIsValid = $event"
                        />
                    </v-col>
                    <v-col cols="6">
                        <div
                            style="
                                background-color: rgb(55, 55, 55);
                                padding: 25px;
                            "
                        >
                            <h1
                                style="
                                    font-size: 36px;
                                    letter-spacing: -0.08rem;
                                "
                            >
                                Your Order
                            </h1>
                            <p
                                >Please confirm your order or
                                <router-link to="/parts-order/cart"
                                    >go back to your cart</router-link
                                >.</p
                            >
                            <v-divider
                                style="margin-bottom: 0px; border-color: gray"
                            />
                            <v-data-table
                                style="background-color: transparent"
                                dark
                                :headers="headers"
                                disable-sort
                                :items="cart"
                                disable-pagination
                                hide-default-footer
                                loading-text="Loading..."
                                no-data-text="No items in cart"
                            >
                                <template v-slot:item.partSpec="{ item }">
                                    {{ item.partSpec }}
                                    <span
                                        v-if="item.isHighValue === 'Y'"
                                        style="color: #db5120"
                                        >*</span
                                    >
                                </template>
                                <template v-slot:item.serial="{ item }">
                                    {{ item.serials }}
                                </template>
                            </v-data-table>
                            <v-divider
                                style="
                                    margin-bottom: 10px;
                                    border-color: rgb(56, 56, 56);
                                    width: 90%;
                                "
                            />
                            <div style="text-align: right">
                                <p style="font-size: 10px; margin-left: 5px"
                                    ><span style="color: #db5120">*</span>
                                    indicates a high value part.</p
                                >
                            </div>
                            <div @click="validateOnContinue">
                                <v-btn
                                    color="primary"
                                    elevation="0"
                                    style="
                                        display: block;
                                        margin: auto;
                                        margin-top: 20px;
                                    "
                                    :disabled="submitted || !formValid"
                                    @click="createOrder"
                                >
                                    Create Order
                                </v-btn>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-snackbar v-model="snackbar">
            {{ snackbarMessage }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    color="white"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import AddressForm from '../../components/create_rma_stepper/forms/address_form.vue'
import ContactForm from '../../components/create_rma_stepper/forms/contact_form.vue'
import formRules from '@/utils/form_rules'
import { mapGetters, mapActions } from 'vuex'
import gql from 'graphql-tag'

export default {
    name: `PartsOrderCheckout`,
    components: {
        AddressForm,
        ContactForm
    },
    data() {
        return {
            orderItems: [],
            snackbar: false,
            valid: null,
            statesLoading: false,
            citiesLoading: false,
            states: [],
            selectedState: {},
            apo: false,
            snackbarMessage: '',
            formRules,
            apoCity: '',
            submitted: false,
            contactFormIsValid: false,
            addressFormIsValid: false,
            showErrors: false,
            partsOrderWarningMessage: `International Part orders are not support on the Service Portal. 
                Please contact Getac Support at GetacSupport_US@Getac.com or +1-866-394-3822 for assistance.`,
            contactData: {
                firstName: ``,
                lastName: ``,
                email: ``,
                phone: ``,
                company: ``,
                phoneCountryCode: ``
            },
            addressData: {
                city: ``,
                country: ``,
                line1: ``,
                line2: ``,
                postalCode: ``,
                state: ``
            },
            headers: [
                {
                    text: 'Product',
                    value: 'partSpec',
                    align: 'left'
                },
                {
                    text: 'Serial',
                    value: 'serial',
                    align: 'left'
                },
                {
                    text: 'Model',
                    value: 'model',
                    align: 'left'
                }
            ]
        }
    },
    computed: {
        ...mapGetters({
            cart: `cart/cart`
        }),
        formValid() {
            return !!(this.contactFormIsValid && this.addressFormIsValid)
        },
        userStoredAddress() {
            if (this.$auth.user.address && this.$auth.user.address.shipping) {
                return {
                    city: this.$auth.user.address.shipping.city,
                    country: this.$auth.user.address.shipping.country,
                    line1: this.$auth.user.address.shipping.line1,
                    line2: this.$auth.user.address.shipping.line2,
                    postalCode: this.$auth.user.address.shipping.postalCode,
                    state: this.$auth.user.address.shipping.state
                }
            } else return {}
        },
        userStoredContact() {
            if (this.$auth.user) {
                return {
                    company: this.$auth.user.company,
                    email: this.$auth.user.email,
                    firstName: this.$auth.user.firstName,
                    lastName: this.$auth.user.lastName,
                    phone: this.$auth.user.phone,
                    phoneCountryCode: this.$auth.user.phoneCountryCode
                }
            } else return {}
        },
        international() {
            return this.addressData.country && this.addressData.country !== `US`
        },
        canada() {
            return this.addressData.country && this.addressData.country === 'CA'
        },
        countries() {
            // return this.$store.getters[`location/getCachedCountries`]
            return [{ name: 'United States', countryCode: 'US'}]
        },

        cities() {
            return this.$store.getters[`location/getCachedCities`]({
                countryCode: this.addressData.country,
                stateCode: this.addressData.state
            })
        },
        stateProvinces() {
            return this.$store.getters[`location/getCachedStates`]({
                countryCode: this.addressData.country
            })
        },
        isApo() {
            const stateObject = this.$store.getters[`location/getCachedStates`](
                {
                    countryCode: this.addressData.country
                }
            ).find((e) => e.stateCode === this.addressData.state) || {
                apo: false
            }
            return !!stateObject.apo
        }
    },
    methods: {
        async getCities(event) {
            this.citiesLoading = true
            try {
                const [countryCode, stateCode] = event
                await this.$store.dispatch(`location/cacheCities`, {
                    countryCode,
                    stateCode
                })
            } catch {
                this.citiesLoading = false
            } finally {
                this.citiesLoading = false
            }
        },
        async getStates(countryCode) {
            this.statesLoading = true
            try {
                await this.$store.dispatch(`location/cacheStates`, {
                    countryCode
                })
                this.statesLoading = false
            } catch {
                this.statesLoading = false
            }
        },
        resetForm() {
            this.$refs.contactForm.resetForm()
            this.$refs.addressForm.resetForm()
        },
        validateOnContinue() {
            this.$refs.contactForm.$children[0].validate()
            this.$refs.addressForm.$children[0].validate()
            if (!this.formValid) this.showErrors = true
            const self = this
            setTimeout(() => {
                self.showErrors = false
            }, 4000)
        },
        ...mapActions({
            remove: `cart/remove`
        }),
        createOrder() {
            if (this.formValid) {
                this.submitted = true
                const allItems = this.checkoutItems(this.cart)
                const checkoutItems = [].concat.apply([], allItems)
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation Mutation($newRma: PartsOrderInputDTO!) {
                                CreatePartsOrder(newRMA: $newRma) {
                                    success
                                    rmaBatchId
                                    error
                                }
                            }
                        `,
                        variables: {
                            newRma: {
                                rmaType: 'PARTS_ORDER',
                                customer: {
                                    shipping: {
                                        address: this.addressData,
                                        contact: {
                                            firstName:
                                                this.contactData.firstName,
                                            lastName: this.contactData.lastName,
                                            email: this.contactData.email,
                                            phone: this.contactData.phone,
                                            company: this.contactData.company
                                        }
                                    }
                                },
                                items: checkoutItems
                            }
                        }
                    })
                    .then((data) => {
                        if (data.data.CreatePartsOrder.success === true) {
                            this.cart.forEach((item) => {
                                this.remove({ ...item })
                            })
                            this.$router.push({
                                name: `parts-order-success`,
                                params: {
                                    rmaBatchId:
                                        data.data.CreatePartsOrder.rmaBatchId
                                }
                            })
                        } else {
                            this.snackbar = true
                            this.snackbarMessage =
                                'Error Creating Parts Order: ' +
                                data.data.CreatePartsOrder.error
                            this.submitted = false
                        }
                    })
                    .catch((error) => {
                        this.snackbar = true
                        this.snackbarMessage =
                            'Error Creating Parts Order: ' + error
                        this.submitted = false
                    })
            } else {
                this.snackbar = true
                this.snackbarMessage =
                    'Please fill out all of the required fields'
            }
        },
        checkoutItems(items) {
            return items.map((item) => {
                const lineItem = item
                return {
                    pn: lineItem.pn,
                    quantity: 1,
                    serial: lineItem.serials
                }
            })
        }
    }
}
</script>
